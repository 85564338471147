import React from "react";
import { withPrefix, Link } from "gatsby";
import { useLocation } from "@reach/router";
import { Button, Row, Col, Container } from "react-bootstrap";
import SEO from "@components/SEO";

export default function Form() {
    const { search } = useLocation();

    return (
        <Container fluid>
            <SEO title="Form">
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link
                    href="https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap"
                    rel="stylesheet"
                />
                <script
                    src={withPrefix("ooscript.js")}
                    type="text/javascript"
                />
            </SEO>
            <Row className="ooef-container">
                <Col xs={12} md={6} lg={4}>
                    <div className="title">
                        <p>
                            Untuk kemudahan berkomunikasi,
                            <br />
                            silahkan isi form di bawah ini:
                        </p>
                        <p className="info">
                            Setelah mengisi form, Anda akan menuju halaman
                            informasi alamat dan nomor telepon Gudang Seragam
                        </p>
                    </div>
                    <div class="ooef">
                        <form
                            class="orderonline-embed-form"
                            data-username="gudangseragam"
                            data-product-slug="konveksi-polo-shirt"
                            id="oo-embed-form-konveksi-polo-shirt-2971"
                        >
                            <div class="ooef-loader">
                                <div aria-live="polite" role="status">
                                    <div>Loading...</div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="alternate">
                        <p className="info">
                            Jika form di atas bermasalah, silahkan klik tombol
                            di bawah ini untuk menuju ke halaman form alternatif
                        </p>
                        <Button as={Link} to={`/backup-form/${search}`}>
                            Form Alternatif
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
